import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useApp } from './../hooks/use-app-context'

import Header from './layout/header'
import Footer from './layout/footer'

function Layout({ children, restricted }) {
  const [, state, send] = useApp()

  useEffect(() => {
    if (restricted && state.matches('unauthorized')) send('LOGIN')
  }, [state, restricted, send])

  return (
    <div className="flex flex-col min-h-screen font-sans antialiased text-gray-900 bg-white">
      <div className="container px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"></div>
      <Header />

      <main className="container flex-1 px-4 mx-auto sm:px-6 lg:px-8">
        {/* {restricted && !state.matches('ready') ? (
          <p className="mt-24 text-xl text-gray-400 ">
            App is {state.value}
            <button
              className="ml-2 text-gray-500 underline"
              onClick={() => send('RETRY')}
            >
              RETRY
            </button>
            <button
              className="ml-2 text-gray-500 underline"
              onClick={() => send('LOGOUT')}
            >
              LOG OUT
            </button>
          </p>
        ) : ( */}
        {children}
        {/* )} */}
      </main>

      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  restricted: PropTypes.bool,
}

export default Layout
